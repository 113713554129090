<template>
  <div class="gf_col-sm-12 gf_col-md-12 main--body--content">

    <form class="form" @submit.prevent="updateDomain">
      <VTextarea
        dense
        outlined
        label="List stores can increase upper share limit ( up to 300 ) (each domain is on a line)"
        placeholder="noragem.myshopify.com"
        v-model="inputDomains"
      />

      <div class="gf_col-sm-12 gf_col-md-12 button-save-form">
        <VBtn color="primary" type="submit">Update</VBtn>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import shopAPI from "@/api/shop.js";

export default {
  name: "DashboardV3",
  components: {},
  data() {
    return {
      previousDomains: "",
      inputDomains: ""
    };
  },
  computed: {
    ...mapState({
      domains: state => state.shop.list,
      loading: state => state.shop.loading,
      fetched: state => state.shop.fetched
    }),
    submitForm() {
      let submitForm = { wrapper: this.form };

      return submitForm;
    },
    inputList() {
      return this.inputDomains.split("\n").filter(e => e.trim().length > 0);
    },
    previousList() {
      return this.previousDomains.split("\n").filter(e => e.trim().length > 0);
    },
    dataSubmit() {
      let data = [];
      this.inputList.forEach(e => {
        data.push({ shopify_domain: e, value: true });
      });

      this.previousList.forEach(e => {
        if (!this.inputList.includes(e)) {
          data.push({ shopify_domain: e, value: false });
        }
      });

      return data;
    }
  },
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      SHOP_FETCH_DOMAINS: "SHOP_FETCH_DOMAINS"
    }),
    ...mapActions({
      fetchDomains: "fetchDomains"
    }),
    updateDomain() {
      this.OpenLoading();

      shopAPI
        .updateDomain(this.dataSubmit)
        .then(res => {
          let newList = [];
          this.dataSubmit.forEach(e => {
            newList.push({
              shopify_domain: e.shopify_domain,
              extras: { dashboardv3: true }
            });
          });
          console.log(newList);
          this.SHOP_FETCH_DOMAINS(newList);

          let list = [];
          this.dataSubmit.forEach(element => {
            list.push(element.shopify_domain);
          });
          list = list.filter(e => e.trim().length > 0);
          list = list.join("\n");
          this.previousDomains = list;
          this.inputDomains = list;

          this.OpenSuccess({
            message: "Update successfully!",
            time: 2000
          });
          this.CloseLoading();
        })
        .catch(err => {
          this.CloseLoading();
          this.OpenError({ message: `<p>${err}</p>` });
        });
    },
    updateData() {
      let list = [];
      this.domains.forEach(element => {
        list.push(element.shopify_domain);
      });
      list = list.filter(e => e.trim().length > 0);
      list = list.join("\n");
      this.previousDomains = list;
      this.inputDomains = list;
    }
  },
  created() {
    document.title = "Shop Extras Management";
    if (!this.fetched) this.fetchDomains();
  },
  mounted() {
    this.updateData();
  },
  watch: {
    fetched() {
      this.updateData();
    }
  }
};
</script>
<style lang="scss" scoped>
.gf_section-2 .main--body .form {
  padding: 4em 0;
}
</style>
